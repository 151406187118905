import { MarkerClusterer } from '@googlemaps/markerclusterer';

import { Plugin, settings } from '@dipcode/dj-core';
import { EntityService } from '@app/services';

declare const google: any;

export class EntitiesMapPlugin extends Plugin {
  private static LISBON = { lat: 38.736946, lng: -9.142685 };
  private static DEFAULT_ZOOM = 6;
  private static MARKER_COLOR = '#567876';
  private static WHITE = '#ffffff';

  private entitiesLocation = new Object();

  private infoWindow: google.maps.InfoWindow;
  private map: google.maps.Map;
  private markerClusterer: MarkerClusterer;
  private filterForm: HTMLFormElement;
  private previousParams: URLSearchParams;
  private indexPageSlug: string;

  public applyToElement(element: HTMLElement) {
    const onSearchChange = (event: CustomEvent<{ params: URLSearchParams }>) => {
      if (!this.previousParams || this.previousParams.toString() != event.detail.params.toString()) {
        this.previousParams = event.detail.params;

        if (this.markerClusterer) {
          this.populateMapMarkers(this.previousParams);
        }
      }
    };

    this.filterForm = document.querySelector('[data-maps-form]');
    this.indexPageSlug = this.filterForm.dataset.mapsForm;
    this.filterForm.addEventListener('searchchange', onSearchChange);

    if (settings.get('MAPS_API_KEY')) {
      const script = document.createElement('script');

      script.src = `https://maps.googleapis.com/maps/api/js?key=${settings.get(
        'MAPS_API_KEY'
      )}&callback=onMapsLoaded&language=${settings.get('LANGUAGE')}`;
      script.async = true;
      script.defer = true;
      document.body.appendChild(script);

      window.onMapsLoaded = () => {
        this.initMap(element);
      };
    } else {
      element.remove();
      this.filterForm.removeEventListener('searchchange', onSearchChange);
      console.warn('Google Maps is disabled. If this is not intended, please check MAPS_API_KEY configurations.');
    }
  }

  private initMap(element: HTMLElement): void {
    this.map = new google.maps.Map(element, {
      disableDefaultUI: true,
      zoomControl: true,
      clickableIcons: false,
      center: EntitiesMapPlugin.LISBON,
      zoom: EntitiesMapPlugin.DEFAULT_ZOOM,
      styles: this.getMapStyles(),
      maxZoom: 18,
      backgroundColor: EntitiesMapPlugin.WHITE,
    });

    this.infoWindow = new google.maps.InfoWindow({
      content: '',
    });

    this.markerClusterer = new MarkerClusterer({ map: this.map, renderer: this.markerClustererRenderer });

    this.populateMapMarkers(this.previousParams || new URLSearchParams());
  }

  private populateMapMarkers(params: URLSearchParams): void {
    const bounds = new google.maps.LatLngBounds();
    this.getMarkers(bounds, params).then((markers) => {
      this.markerClusterer.clearMarkers();

      if (markers && markers.length > 0) {
        this.markerClusterer.addMarkers(markers);
        this.map.fitBounds(bounds);
      } else {
        this.map.setCenter(EntitiesMapPlugin.LISBON);
        this.map.setZoom(EntitiesMapPlugin.DEFAULT_ZOOM);
      }
    });
  }

  private getMarkers(bounds: google.maps.LatLngBounds, params: URLSearchParams) {
    return EntityService.getEntitiesList(this.indexPageSlug, params).then((entities) => {
      this.entitiesLocation = {};
      for (const entity of entities) {
        if (
          !entity.latitude ||
          !entity.longitude ||
          (parseFloat(entity.latitude) == 0 && parseFloat(entity.longitude) == 0)
        )
          continue;
        const key = String([entity.latitude, entity.longitude]);
        if (!this.entitiesLocation[key]) {
          this.entitiesLocation[key] = [entity];
        } else {
          this.entitiesLocation[key].push(entity);
        }
      }
      return this.createMarker(bounds, this.entitiesLocation);
    });
  }

  /**
   * If 1 or more entity has the same lat and lng
   * agreggates these in 1 only marker, but draws
   * (nª entities - 1) markers.
   * Example: 3 Entities with same geolocation (A,B and C)
   * Draw A and B and, the third one, is a marker with
   * A,B and C together in one
   */
  private createMarker(bounds: google.maps.LatLngBounds, entitiesLocation: any): google.maps.Marker[] {
    const markers = [];
    const entitiesList = Object.keys(entitiesLocation).map(function (key) {
      return entitiesLocation[key];
    });
    for (const entitiesWithSameLngLat of entitiesList) {
      const numberOfEntities = entitiesWithSameLngLat.length;
      for (let i = 1; i < numberOfEntities; i++) {
        markers.push(this.drawAggregateMarker(bounds, [entitiesWithSameLngLat[i]], 1));
      }
      markers.push(this.drawAggregateMarker(bounds, entitiesWithSameLngLat, numberOfEntities));
    }
    return markers;
  }

  private drawAggregateMarker(bounds: google.maps.LatLngBounds, entityList: any[], numberOfEntities: number) {
    const point = new google.maps.LatLng(entityList[0].latitude, entityList[0].longitude);
    const marker = new google.maps.Marker({
      position: point,
      title: numberOfEntities === 1 ? entityList[0].title : 'Mais de 1 entidade',
      label:
        numberOfEntities === 1
          ? null
          : {
              text: String(numberOfEntities),
              color: EntitiesMapPlugin.WHITE,
            },
      icon: {
        path: google.maps.SymbolPath.CIRCLE,
        fillColor: EntitiesMapPlugin.MARKER_COLOR,
        fillOpacity: 1,
        scale: 8,
        strokeColor: EntitiesMapPlugin.MARKER_COLOR,
        strokeWeight: 1,
      },
    });

    bounds.extend(point);

    let markerContent = '<ul class="mb-0">';
    if (numberOfEntities !== 1) {
      entityList.forEach(
        (singleEntity: any) =>
          (markerContent += `<li><a href="${singleEntity.url}"><strong>${singleEntity.title}<strong></a></li>`)
      );
      markerContent += '</ul>';
    } else {
      markerContent = `<li><a href="${entityList[0].url}"><strong>${entityList[0].title}<strong></a></li></ul>`;
    }

    marker.addListener('click', () => {
      this.infoWindow.setContent(markerContent);
      this.infoWindow.open(this.map, marker);
    });
    return marker;
  }

  private markerClustererRenderer = {
    render: ({ count, position }) => {
      // create svg url with fill color
      const svg = window.btoa(`
    <svg fill="${EntitiesMapPlugin.MARKER_COLOR}" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 240 240">
      <circle cx="120" cy="120" opacity="1" r="70" />
      <circle cx="120" cy="120" opacity=".5" r="90" />
      <circle cx="120" cy="120" opacity=".3" r="110" />
    </svg>`);
      // create marker using svg icon
      return new google.maps.Marker({
        position,
        icon: {
          url: `data:image/svg+xml;base64,${svg}`,
          scaledSize: new google.maps.Size(45, 45),
        },
        label: {
          text: String(count),
          color: EntitiesMapPlugin.WHITE,
          fontSize: '12px',
        },
        title: `Cluster of ${count} markers`,
        // adjust zIndex to be above other markers
        zIndex: Number(google.maps.Marker.MAX_ZINDEX) + count,
      });
    },
  };

  private getMapStyles(): google.maps.MapTypeStyle[] {
    return [
      {
        elementType: 'geometry',
        stylers: [{ color: '#ffffff' }],
      },
      {
        elementType: 'labels.text.fill',
        stylers: [{ color: '#242424' }],
      },
      {
        featureType: 'administrative',
        elementType: 'geometry.stroke',
        stylers: [{ color: '#9c9c9c' }],
      },
      {
        featureType: 'landscape',
        elementType: 'labels.icon',
        stylers: [{ visibility: 'off' }],
      },
      {
        featureType: 'poi',
        stylers: [{ visibility: 'off' }],
      },
      {
        featureType: 'road',
        elementType: 'geometry',
        stylers: [{ color: '#eeeeee' }],
      },
      {
        featureType: 'road',
        elementType: 'labels.icon',
        stylers: [{ visibility: 'off' }],
      },
      {
        featureType: 'transit',
        stylers: [{ visibility: 'off' }],
      },
      {
        featureType: 'water',
        elementType: 'geometry',
        stylers: [{ color: '#cbd7d4' }],
      },
    ];
  }
}
