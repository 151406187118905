import IMask from 'imask';

import { Plugin } from '@dipcode/dj-core';

export class InputMaskPlugin extends Plugin<HTMLElement> {
  private readonly maskMapper = {
    postalCode: '0000-000',
  };

  protected applyToElement(element: HTMLElement) {
    const mask = element.dataset.inputMask;

    IMask(element, {
      mask: this.maskMapper[mask] || mask,
    });
  }
}
