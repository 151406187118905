import SimpleBar from 'simplebar';

import 'simplebar/src/simplebar.css';

import { Plugin } from '@dipcode/dj-core';

/**
 * Instantiate bootstrap carousel only on defined breakpoint screens.
 *
 * @export
 * @class SimpleBarPlugin
 * @extends {Plugin}
 */
export class SimpleBarPlugin extends Plugin {
  /**
   * Only execute on bootstrap.
   *
   * @protected
   * @memberof SimpleBarPlugin
   */
  protected bootstrapOnly = true;

  /**
   * Instantiate conditionally carousel.
   *
   * @memberof SimpleBarPlugin
   */
  public applyToElement(element: HTMLElement) {
    new SimpleBar(element);
  }
}
