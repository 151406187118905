import * as React from 'react';

export function ProfilePicture({ logo }) {
  if (!logo) {
    return (
      <div className="default-avatar">
        <i className="idd-icon idd-icon-user" aria-hidden="true"></i>
      </div>
    );
  }
  return <img src={logo.url} width="72" height="72" alt="Profile picture"></img>;
}
