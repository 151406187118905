import { Plugin, Utils } from '@dipcode/dj-core';

export class FilterElementsPlugin extends Plugin {
  static ACTIVE_CLASS = 'active';

  filterByElements: NodeListOf<HTMLElement>;
  filterKeyElements: NodeListOf<HTMLElement>;

  protected applyToElement(element: HTMLElement): void {
    this.filterByElements = element.querySelectorAll<HTMLElement>('[data-filter-by]');
    this.filterKeyElements = element.querySelectorAll<HTMLElement>(`[data-filter-key]`);

    this.filterByElements.forEach((elem) =>
      elem.addEventListener('click', (event: MouseEvent) => this.handleFilterClick(event, elem))
    );
  }

  private handleFilterClick(event: MouseEvent, element: HTMLElement) {
    event.preventDefault();

    if (element.classList.contains(FilterElementsPlugin.ACTIVE_CLASS)) {
      element.classList.remove(FilterElementsPlugin.ACTIVE_CLASS);
      this.filterKeyElements.forEach((e) => Utils.toggleElement(e, false));
      return;
    }

    this.filterByElements.forEach((e) => e.classList.remove(FilterElementsPlugin.ACTIVE_CLASS));
    element.classList.add(FilterElementsPlugin.ACTIVE_CLASS);
    this.filterKeyElements.forEach((e) => Utils.toggleElement(e, e.dataset.filterKey === element.dataset.filterBy));
  }
}
