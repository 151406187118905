import { useStoreActions } from 'easy-peasy';
import * as React from 'react';

import { CommentForm } from './CommentForm';
import { CommentList } from './CommentList';
import { ICommentGlobalModel, Comments } from './comment-store';


export function Comment() {
  const obtainUser = useStoreActions<ICommentGlobalModel>((state) => state.obtainUser);

  React.useEffect(() => {
    obtainUser();
  }, []);

  return (
    <>
      <Comments.Provider>
        <CommentForm />

        <div className="comments-list">
          <CommentList />
        </div>
      </Comments.Provider>
    </>
  );
}
