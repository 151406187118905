import * as React from 'react';

import { Comment } from '@app/models';

import { CommentItem } from './CommentItem';
import { useStoreState } from 'easy-peasy';
import { ICommentGlobalModel, Comments } from './comment-store';

declare const django: any;


export function CommentList({ thread = null, level = 1 }) {
  const post = useStoreState<ICommentGlobalModel, number>((state) => state.post);

  const hasMorePages = Comments.useStoreState<boolean>((state) => state.hasMorePages);
  const comments = Comments.useStoreState<Comment[]>((state) => state.items);
  const obtainComments = Comments.useStoreActions((state) => state.obtainList);

  const [page, setPage] = React.useState(1);
  const [isLoading, setIsLoading] = React.useState(false);

  React.useEffect(() => {
    setIsLoading(true);

    obtainComments({ page, post, thread, level }).finally(() => setIsLoading(false));
  }, [page, thread, level]);

  const handleMoreCommentsClick = (event: React.MouseEvent<HTMLAnchorElement>) => {
    event.preventDefault();
    setPage(page + 1);
  }

  return (
    <>
      {comments && comments.map((item: Comment) => (
        <CommentItem key={item.id} comment={item} />
      ))}

      {hasMorePages && (
        <a href="#" onClick={handleMoreCommentsClick}>
          {isLoading ? (
            <div className="d-flex justify-content-center">
              <div className={"spinner-border " + (level !== 1 ? "spinner-border-sm" : "")} role="status">
                <span className="visually-hidden">{django.gettext("Loading comments...")}</span>
              </div>
            </div>
          ) : (
            <>
              {level === 1 ? django.gettext('+ Read more comments') : django.gettext('+ Read more replies') }
            </>
          )}
        </a>
      )}
    </>
  );
}
