import * as React from 'react';
import axios, { AxiosError } from 'axios';
import { useStoreState } from 'easy-peasy';

import { User, Comment } from '@app/models';

import { ProfilePicture } from './ProfilePicture';
import { ICommentGlobalModel, Comments } from './comment-store';

declare const django: any;


export function CommentForm({ parent = null, onCancel = null }) {
  const user = useStoreState<ICommentGlobalModel, User>((state) => state.user);
  const post = useStoreState<ICommentGlobalModel, number>((state) => state.post);

  const createComment = Comments.useStoreActions((actions) => actions.create);

  const [isLoading, setIsLoading] = React.useState(false);
  const [comment, setComment] = React.useState('');
  const [commentError, setCommentError] = React.useState([]);

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    setIsLoading(true);

    createComment({ post, parent: parent && parent.id, comment }).then(
      (comment?: Comment) => {
        // if no comment created, don't empty textarea
        if (comment) {
          setComment('');
          setCommentError([]);
        }
        if (parent && onCancel) {
          onCancel();
        }
      },
      (error: Error | AxiosError<any>) => {
        if (axios.isAxiosError(error) && error.response.status === 400) {
          if ('comment' in error.response.data) {
            setCommentError(error.response.data.comment);
          }
        }
      }
    ).finally(() => setIsLoading(false));
  };

  return (
    <>
      {!parent && user && (
        <div className="comment-form-top profile-picture">
          <ProfilePicture logo={user.logo} />
          <span>{django.gettext('Comment as ') + user.name}</span>
        </div>
      )}

      <form method="POST" onSubmit={handleSubmit} className="comment-form">
        <div className="form-group">
          <textarea
            name="comment"
            rows={4}
            value={comment}
            className={'form-control form-control-placeholder' + (commentError.length ? ' is-invalid' : '')}
            placeholder={django.gettext("Write comment")}
            onChange={(e) => setComment(e.target.value)}
            disabled={isLoading}
          ></textarea>
          {commentError.length ? (
            <div className="invalid-feedback">{commentError.map((errorMsg) => errorMsg)}</div>
          ) : null}
        </div>

        <div className="form-buttons">
          {parent && (
            <button type="button" onClick={() => onCancel && onCancel()} className="btn btn-outline-primary">
              <span className="btn-label">{django.gettext("Cancel")}</span>
            </button>
          )}

          <button type="submit" className="btn btn-primary" disabled={isLoading}>
            <span className="btn-label">{parent ? django.gettext('Reply') : django.gettext('Comment')} </span>
          </button>
        </div>
      </form>
    </>
  );
}
