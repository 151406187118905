import { Plugin, Utils } from '@dipcode/dj-core';

export class ImagePreviewerPlugin extends Plugin {
  /**
   * Image element where to show the image file
   *
   * @private
   * @type {HTMLImageElement}
   * @memberof ImagePreviewerPlugin
   */
  private imagePreviewElement: HTMLImageElement;

  /**
   * Element to remove image preview and the file associated with it-
   *
   * @private
   * @type {HTMLElement}
   * @memberof ImagePreviewerPlugin
   */
  private removePreviewElement: HTMLElement;

  /**
   * Input element to check image for removal
   *
   * @private
   * @type {HTMLInputElement}
   * @memberof ImagePreviewerPlugin
   */
  private clearImageInputElem: HTMLInputElement;

  /**
   * Element that the plugin is attached to
   *
   * @private
   * @type {HTMLInputElement}
   * @memberof ImagePreviewerPlugin
   */
  private element: HTMLInputElement;

  protected applyToElement(element: HTMLInputElement): void {
    this.element = element;
    this.imagePreviewElement = document.querySelector<HTMLImageElement>('[data-image-preview]');
    this.removePreviewElement = document.querySelector<HTMLElement>(`[data-image-preview-remove="#${element.id}"]`);
    this.clearImageInputElem = element.parentElement.querySelector<HTMLInputElement>(
      `input[name="${element.name}-clear"]`
    );

    element.addEventListener('change', (event: Event) => this.onFilesChanged(event));

    this.removePreviewElement?.addEventListener('click', (event: MouseEvent) => this.onRemovePreview(event));
  }

  private onFilesChanged(event: Event) {
    const target = event.target as HTMLInputElement;

    if (!target.files.length) {
      return this.onFilesCleared();
    }

    this.imagePreviewElement.classList.remove(Utils.HIDE_CLASS);
    if (this.removePreviewElement) {
      this.removePreviewElement.classList.remove(Utils.HIDE_CLASS);
    }
    this.imagePreviewElement.src = URL.createObjectURL(target.files[0]);
    this.imagePreviewElement.onload = () => URL.revokeObjectURL(this.imagePreviewElement.src); // free memory
  }

  private onFilesCleared() {
    this.imagePreviewElement.classList.add(Utils.HIDE_CLASS);
    if (this.clearImageInputElem) {
      this.clearImageInputElem.checked = true;
    }
    return;
  }

  private onRemovePreview(event: MouseEvent) {
    event.preventDefault();

    this.removePreviewElement.classList.add(Utils.HIDE_CLASS);
    this.element.value = null;
    this.element.dispatchEvent(new Event('change', { bubbles: true }));
  }
}
