import axios, { AxiosResponse } from 'axios';
import { Action, action, Actions, createContextStore, Thunk, thunk } from 'easy-peasy';

import { settings } from '@dipcode/dj-core';
import { Comment, PaginatedResult, User } from '@app/models';

export interface ICommentModel {
  items: Comment[];
  hasMorePages: boolean;
  setHasMorePages: Action<ICommentModel, boolean>;
  create: Thunk<ICommentModel, Comment>;
  delete: Thunk<ICommentModel, number>;
  report: Thunk<ICommentModel, Comment>;
  unshift: Action<ICommentModel, Comment>;
  remove: Action<ICommentModel, number>;
  appendList: Action<ICommentModel, Comment[]>;
  obtainList: Thunk<ICommentModel, ICommentSearch>;
}

export interface ICommentGlobalModel {
  user: User;
  setUser: Action<ICommentGlobalModel, User>;
  obtainUser: Thunk<ICommentGlobalModel, User>;

  post: number;
  setPost: Action<ICommentGlobalModel, number>;
}

export interface ICommentSearch {
  post: number;
  page: number;
  thread?: number;
  level?: number;
}

export interface ICommentReportResponse {
  action: string;
  label: string;
  feedback: string;
}

export const Comments = createContextStore({
  items: [],

  hasMorePages: false,

  setHasMorePages: action((state: ICommentModel, payload: boolean) => {
    state.hasMorePages = payload;
  }),

  create: thunk((actions: Actions<ICommentModel>, payload: Comment): Promise<Comment> => {
    return axios
      .post(`${settings.get('API_BASE_ENDPOINT')}comments/comment/`, payload, {
        withCredentials: true,
        xsrfCookieName: 'csrftoken',
        xsrfHeaderName: 'X-CSRFToken',
      })
      .then((response: AxiosResponse) => {
        actions.unshift(response.data);

        return response.data;
      });
  }),

  delete: thunk((actions: Actions<ICommentModel>, payload: number): Promise<void> => {
    return axios
      .delete(`${settings.get('API_BASE_ENDPOINT')}comments/comment/${payload}/`, {
        withCredentials: true,
        xsrfCookieName: 'csrftoken',
        xsrfHeaderName: 'X-CSRFToken',
      })
      .then(() => actions.remove(payload));
  }),

  report: thunk((_actions: Actions<ICommentModel>, payload: Comment): Promise<ICommentReportResponse> => {
    return axios
      .post(
        `${settings.get('API_BASE_ENDPOINT')}comments/comment/${payload.id}/report/`,
        {},
        {
          withCredentials: true,
          xsrfCookieName: 'csrftoken',
          xsrfHeaderName: 'X-CSRFToken',
        }
      )
      .then((response: AxiosResponse<ICommentReportResponse>) => response.data);
  }),

  unshift: action((state: ICommentModel, payload: Comment) => {
    state.items = [payload, ...state.items];
  }),

  remove: action((state: ICommentModel, payload: number) => {
    state.items = state.items.filter((comment: Comment) => comment.id !== payload);
  }),

  appendList: action((state: ICommentModel, payload: Comment[]) => {
    state.items = [...state.items, ...payload];
  }),

  obtainList: thunk((actions: Actions<ICommentModel>, payload: ICommentSearch): Promise<Comment[]> => {
    return axios
      .get<PaginatedResult<Comment>>(`${settings.get('API_BASE_ENDPOINT')}comments/comment/`, {
        params: { page: payload.page, post_id: payload.post, thread_id: payload.thread, level: payload.level },
      })
      .then((response: AxiosResponse<PaginatedResult<Comment>>) => {
        response.data.results.map((comment: Comment) => {
          comment.created = new Date(comment.created);
          return comment;
        });

        actions.setHasMorePages(!!response.data.next);

        if (response.data.count) {
          actions.appendList(response.data.results);
        }

        return response.data.results;
      });
  }),
});

export const globalModel: ICommentGlobalModel = {
  // user context
  user: null,

  setUser: action((state: ICommentGlobalModel, payload: User) => {
    state.user = payload;
  }),

  obtainUser: thunk(async (actions: Actions<ICommentGlobalModel>, _payload: any): Promise<User> => {
    return axios.get(`${settings.get('API_BASE_ENDPOINT')}accounts/user/me/`).then((response: AxiosResponse) => {
      actions.setUser(response.data);
      return response.data;
    });
  }),

  // post context
  post: null,

  setPost: action((state: ICommentGlobalModel, payload: number) => {
    state.post = payload;
  }),
};
