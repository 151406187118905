import { Plugin } from '@dipcode/dj-core';
import { GoogleAnalyticsService } from '@app/services';

declare const gtag: any;

export class AnalyticsClientIdPlugin extends Plugin {
  protected applyToElement(element: HTMLInputElement): void {
    GoogleAnalyticsService.getClientId()
      .then((clientId) => (element.value = clientId as string))
      .catch(() => element.remove());
  }
}
