import { Script } from '@dipcode/dj-core';

// tslint:disable: ordered-imports
import 'bootstrap/js/dist/collapse';
import 'bootstrap/js/dist/dropdown';
import 'bootstrap/js/dist/modal';
import 'bootstrap/js/dist/tab';
import 'bootstrap/js/dist/carousel';
import Tooltip from 'bootstrap/js/dist/tooltip';

/**
 * https://getbootstrap.com/docs/4.3/getting-started/javascript/
 *
 * @export
 * @class BootstrapScript
 */
export class BootstrapScript extends Script {
  /**
   * Only execute on bootstrap.
   *
   * @protected
   * @memberof BootstrapScript
   */
  protected bootstrapOnly = true;

  /**
   * Append the cookieconsent script in body and define behavior after load.
   *
   * @memberof BootstrapScript
   */
  public execute() {
    document.querySelectorAll('[data-bs-toggle="tooltip"]').forEach((elem: Element) => new Tooltip(elem));
  }
}
