import {
  BarController,
  BarElement,
  CategoryScale,
  Chart,
  LinearScale,
  ChartType,
  LineElement,
  LineController,
  PointElement,
  Tooltip,
  Title,
} from 'chart.js';

import { Plugin } from '@dipcode/dj-core';

/**
 * Draw check report chart.
 *
 * @export
 * @class ChartPlugin
 * @extends {Plugin}
 */
export class ChartPlugin extends Plugin {
  /**
   * Only execute on bootstrap.
   *
   * @protected
   * @memberof ChartPlugin
   */
  protected bootstrapOnly = true;

  public constructor(selector: string) {
    super(selector);

    Chart.register(
      LinearScale,
      BarController,
      CategoryScale,
      BarElement,
      LineElement,
      LineController,
      PointElement,
      Tooltip,
      Title
    );
  }

  public applyToElement(element: HTMLCanvasElement) {
    const idDataElement = element.getAttribute('data-chartjs');

    if (idDataElement) {
      const dataElement = document.getElementById(idDataElement);

      if (dataElement && dataElement.textContent) {
        let checkReportData = JSON.parse(dataElement.textContent);

        if (!Object.keys(checkReportData).length) {
          return;
        }

        new Chart(element, {
          type: this.getChartType(element.dataset.chartType),
          data: checkReportData,
          options: {
            plugins: {
              title: {
                display: !!element.dataset.chartUnit,
                text: element.dataset.chartUnit,
                position: 'top',
                align: 'start',
                padding: {
                  top: 10,
                  bottom: 30,
                },
              },
            },
          },
        });
      }
    }
  }

  private getChartType(chartType: any): ChartType {
    const keys: ChartType[] = ['bar', 'line'];
    if (keys.includes(chartType)) {
      return chartType;
    }

    return 'bar';
  }
}
