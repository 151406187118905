import { Plugin } from '@dipcode/dj-core';

export class ClearableFilePluginPlugin extends Plugin {
  protected applyToElement(element: HTMLElement): void {
    const fileInput = element.querySelector<HTMLInputElement>('input[type="file"]');
    const fileInputValueTarget = element.querySelector('[data-clearable-file-value]');

    fileInput.addEventListener('change', (event: Event) => {
      const target = event.target as HTMLInputElement;

      let value = '';
      if (target.files.length) {
        for (let index = 0; index < target.files.length; index++) {
          value += `${index > 0 ? ', ' : ''}${target.files[index].name}`;
        }
      }
      fileInputValueTarget.textContent = value;
      fileInputValueTarget.setAttribute('title', value);
    });
  }
}
