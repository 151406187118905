import axios from 'axios';

import { settings } from '@dipcode/dj-core';

export class AccountsService {
  public static userRemoveEntity(slug: string): Promise<any> {
    return axios
      .post(
        `${settings.get('SITE_BASE_URL')}/api/v1/accounts/user/${slug}/remove-entity/`,
        {},
        {
          withCredentials: true,
          xsrfCookieName: 'csrftoken',
          xsrfHeaderName: 'X-CSRFToken',
        }
      )
      .then((response) => response.data);
  }
}
