import { Plugin } from '@dipcode/dj-core';
import { ChoicesService } from '@dipcode/dj-services';

import { EntityService } from '@app/services';

export class BTIDPopulatorPlugin extends Plugin {
  private element: HTMLSelectElement;
  private form: HTMLFormElement;

  protected applyToElement(element: HTMLElement): void {
    this.element = <HTMLSelectElement>element;
    this.form = this.element.closest<HTMLFormElement>('form');

    const onElementSelection = (event) => {
      const pk = parseInt(event.detail.selection.value.value);
      if (isNaN(pk)) return;

      EntityService.getBTID(pk).then((data) => this.populateForm(data));
    };

    this.element.addEventListener('selection', onElementSelection);
  }

  protected populateForm(data: object) {
    for (const [fieldName, value] of Object.entries(data)) {
      const element = this.form.querySelector<HTMLInputElement | HTMLSelectElement>(
        `input[name=${fieldName}],select[name=${fieldName}]`
      );
      if (!element) {
        continue;
      }
      if (ChoicesService.isChoicesSelect(element)) {
        ChoicesService.getChoiceInstance(element.name).setChoiceByValue(value);
      } else {
        element.value = value;
      }
    }
  }
}
