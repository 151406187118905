import Carousel from 'bootstrap/js/dist/carousel';

import { Breakpoint, Plugin, ScreenSize } from '@dipcode/dj-core';

/**
 * Instantiate bootstrap carousel only on defined breakpoint screens.
 *
 * @export
 * @class CarouselOnMatchPlugin
 * @extends {Plugin}
 */
export class CarouselOnMatchPlugin extends Plugin {
  /**
   * Only execute on bootstrap.
   *
   * @protected
   * @memberof CarouselOnMatchPlugin
   */
  protected bootstrapOnly = true;

  /**
   * Carousel instance.
   *
   * @protected
   * @memberof CarouselOnMatchPlugin
   */
  private carousel: Carousel;

  /**
   * Brekpoint until the carousel will be instantiated.
   */
  private breakpoint: ScreenSize;

  /**
   * Creates an instance of Plugin.
   *
   * @param {string} selectr CSS selector to apply this plugin.
   * @memberof Plugin
   */
  public constructor(selector: string, breakpoint?: ScreenSize) {
    super(selector);

    this.breakpoint = breakpoint;
  }

  /**
   * Instantiate conditionally carousel.
   *
   * @memberof CarouselOnMatchPlugin
   */
  public applyToElement(element: HTMLElement) {
    const breakpoint = (element.dataset.carouselOnMatch || this.breakpoint) as ScreenSize;
    const mediaQuery = Breakpoint.down(breakpoint);

    if (mediaQuery.matches) {
      this.initCarousel(element);
    }

    mediaQuery.addEventListener('change', (e: MediaQueryListEvent) => {
      if (e.matches && !this.carousel) {
        this.initCarousel(element);
      }
      if (!e.matches && this.carousel) {
        this.carousel.dispose();
        this.carousel = null;
      }
    });
  }

  /**
   * Init carousel.
   *
   * @param element Element
   * @memberof CarouselOnMatchPlugin
   */
  private initCarousel(element: Element) {
    this.carousel = new Carousel(element);
    this.carousel.cycle();
  }
}
