import { Options, SlideComponent } from '@splidejs/splide';

import { settings, Utils } from '@dipcode/dj-core';
import { EventService } from '@app/services';

import { CarouselPlugin } from './carousel';

export class CalendarCarouselPlugin extends CarouselPlugin {
  private static INPUT_NAME = 'date';
  private static ACTIVE_EVENT_CLASS = 'event-active-date';
  private static SELECTED_CLASS = 'active';

  private element: HTMLElement;

  protected input: HTMLInputElement;

  protected getOptions(): Options {
    return Object.assign(super.getOptions(), {
      gap: '10px',
      mediaQuery: 'min',
      pagination: false,
      perPage: 6,
      arrows: false,
      arrowPath:
        'M 14.109375 0.203125 C 13.355469 0.707031 12.914062 1.496094 13.046875 2.113281 C 13.078125 2.253906 13.152344 2.472656 13.210938 2.597656 C 13.292969 2.769531 14.589844 4.097656 18.433594 7.953125 C 21.246094 10.769531 23.550781 13.09375 23.550781 13.113281 C 23.550781 13.132812 18.566406 13.148438 12.476562 13.148438 L 1.402344 13.148438 L 1.078125 13.257812 C 0.703125 13.378906 0.449219 13.574219 0.269531 13.878906 C -0.09375 14.496094 -0.09375 15.503906 0.269531 16.121094 C 0.523438 16.554688 0.898438 16.765625 1.5625 16.847656 C 1.832031 16.882812 5.667969 16.898438 12.773438 16.898438 L 23.574219 16.898438 L 18.445312 22.035156 C 14.59375 25.898438 13.292969 27.230469 13.210938 27.402344 C 13.152344 27.527344 13.078125 27.746094 13.046875 27.886719 C 12.914062 28.503906 13.375 29.328125 14.121094 29.808594 L 14.414062 30 L 15.585938 30 L 15.878906 29.804688 C 16.070312 29.679688 18.425781 27.355469 22.574219 23.203125 C 26.21875 19.554688 29.085938 16.722656 29.230469 16.625 C 29.535156 16.429688 29.699219 16.222656 29.871094 15.835938 C 29.984375 15.582031 30 15.476562 30 15 C 30 14.523438 29.984375 14.417969 29.871094 14.164062 C 29.707031 13.789062 29.539062 13.574219 29.285156 13.414062 C 29.167969 13.34375 26.152344 10.363281 22.574219 6.796875 C 18.726562 2.957031 15.976562 0.246094 15.828125 0.152344 C 15.589844 0.00390625 15.5625 0 14.996094 0 L 14.410156 0 L 14.109375 0.203125',
      breakpoints: {
        768: {
          perPage: 10,
        },
        1024: {
          perPage: 20,
          arrows: true,
          gap: '16px',
        },
      },
    });
  }

  protected applyToElement(element: HTMLElement): void {
    super.applyToElement(element);

    this.input = element.querySelector<HTMLInputElement>(`input[name=${CalendarCarouselPlugin.INPUT_NAME}]`);
    this.element = element;

    if (!this.input.disabled) {
      this.splide.on('click', (slideComponent: SlideComponent, _e: MouseEvent) => {
        const slide = slideComponent.slide;
        const isSelected = slide.classList.contains(CalendarCarouselPlugin.SELECTED_CLASS);

        this.element
          .querySelectorAll(`.${CalendarCarouselPlugin.SELECTED_CLASS}`)
          .forEach((el) => el.classList.remove(CalendarCarouselPlugin.SELECTED_CLASS));

        this.input.value = isSelected ? null : slide.dataset.value;
        this.input.dispatchEvent(new Event('change', { bubbles: true }));

        if (!isSelected) {
          slide.classList.add(CalendarCarouselPlugin.SELECTED_CLASS);
        }
      });
    }

    const onMove = (_index, _prev, dest) => {
      this.element.querySelectorAll('.year,.month').forEach((el) => el.classList.add(Utils.HIDE_CLASS));

      const maxIndexVisible = dest + this.splide.options.perPage;
      const firstDayVisible = this.splide.Components.Slides.filter((slideComp: SlideComponent) => {
        return (
          slideComp.index >= dest &&
          slideComp.index < maxIndexVisible &&
          new Date(slideComp.slide.dataset.value).getDate() == 1
        );
      });
      firstDayVisible.forEach((slideComp: SlideComponent) => {
        slideComp.slide.querySelector('.month').classList.remove(Utils.HIDE_CLASS);
        if (new Date(slideComp.slide.dataset.value).getMonth() == 0) {
          slideComp.slide.querySelector('.year').classList.remove(Utils.HIDE_CLASS);
        }
      });

      const nextActiveSlide: HTMLElement = this.splide.Components.Slides.getAt(dest).slide;
      const nextActiveSlideDate = new Date(nextActiveSlide.dataset.value);
      const offsetDate = new Date(nextActiveSlideDate);
      offsetDate.setDate(nextActiveSlideDate.getDate() + 2);

      if (
        offsetDate.getFullYear() == nextActiveSlideDate.getFullYear() &&
        !(nextActiveSlideDate.getDate() == 1 && nextActiveSlideDate.getMonth() == 0)
      ) {
        nextActiveSlide.querySelector('.year').classList.remove(Utils.HIDE_CLASS);
      }
      if (offsetDate.getMonth() == nextActiveSlideDate.getMonth() && nextActiveSlideDate.getDate() != 1) {
        nextActiveSlide.querySelector('.month').classList.remove(Utils.HIDE_CLASS);
      }
    };

    this.splide.on('move', onMove);

    this.splide.on('moved', (_index, _prev, dest) => {
      if (dest + this.splide.options.perPage >= this.splide.length) {
        this.populateSliderList();
      }
    });

    this.populateSliderList().then(() => onMove(0, 0, 0));
  }

  private async populateSliderList(): Promise<void> {
    let startDate = new Date();
    let numDatesToAdd = this.splide.options.perPage * 2 - 1;

    if (this.splide.length > 0) {
      const lastSlide: HTMLElement = this.splide.Components.Slides.getAt(this.splide.length - 1).slide;
      startDate = new Date(lastSlide.dataset.value);
      startDate.setDate(startDate.getDate() + 1);
      numDatesToAdd = this.splide.options.perPage - 1;
    }

    const endDate = new Date(startDate);
    endDate.setDate(endDate.getDate() + numDatesToAdd);

    const dates: Date[] = this.getDatesBetween(startDate, endDate);

    const toAdd = [];
    for (let date of dates) {
      toAdd.push(this.createDateElement(date));
    }

    this.splide.add(toAdd);
    this.highlightEventsDates(startDate, endDate);
  }

  private getDatesBetween(start: Date, end: Date): Date[] {
    let dates = [];
    for (let date = new Date(start); date <= end; date.setDate(date.getDate() + 1)) {
      dates.push(new Date(date));
    }
    return dates;
  }

  private highlightEventsDates(start: Date, end: Date): void {
    EventService.getEventsDates(this.getDateString(start), this.getDateString(end)).then((data) =>
      data.results?.forEach((date) =>
        this.element.querySelector(`[data-value='${date}']`).classList.add(CalendarCarouselPlugin.ACTIVE_EVENT_CLASS)
      )
    );
  }

  private getDateString(date: Date): string {
    return date.toISOString().split('T')[0];
  }

  private createDateElement(date: Date): HTMLElement {
    const elem: HTMLElement = document.createElement('li');
    elem.classList.add('splide__slide');
    elem.classList.add('date-slide');
    elem.dataset.value = this.getDateString(date);

    // Mark slide as selected if its value is on query params
    if (!this.input.disabled) {
      const selectedDate = new URL(window.location.href).searchParams.get('date');
      if (selectedDate == elem.dataset.value) elem.classList.add(CalendarCarouselPlugin.SELECTED_CLASS);
    }

    const year = document.createElement('span');
    year.classList.add('year');
    year.classList.add(Utils.HIDE_CLASS);
    year.textContent = date.getFullYear().toString();
    elem.appendChild(year);

    const month = document.createElement('span');
    month.classList.add('month');
    month.classList.add(Utils.HIDE_CLASS);
    month.textContent = date.toLocaleString(settings.get('LANGUAGE') || 'default', { month: 'long' });
    elem.appendChild(month);

    const week_day = document.createElement('span');
    week_day.classList.add('week-day');
    week_day.textContent = date.toLocaleString(settings.get('LANGUAGE') || 'default', { weekday: 'long' })[0];

    const day = document.createElement('span');
    day.classList.add('day');
    day.textContent = date.getDate().toString();

    elem.appendChild(year);
    elem.appendChild(month);
    elem.appendChild(week_day);
    elem.appendChild(day);

    return elem;
  }
}
