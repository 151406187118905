import { Plugin } from '@dipcode/dj-core';

export class MarkForDeletePlugin extends Plugin {
  protected applyToElement(element: HTMLElement): void {
    const btnElement = element.querySelector<HTMLElement>('[data-mark-for-delete-toggle]');
    const inputElement = btnElement.querySelector<HTMLInputElement>('input[type="checkbox"]');
    btnElement.addEventListener('click', (event: MouseEvent) => {
      event.preventDefault();
      inputElement.checked = element.classList.toggle('marked-for-delete');
      inputElement.dispatchEvent(new Event('change', { bubbles: true }));
    });
  }
}
