import * as React from 'react';
import * as ReactDOM from 'react-dom';

import { createStore, StoreProvider } from 'easy-peasy';

import { Plugin } from '@dipcode/dj-core';
import { Comment, globalModel } from '@app/components';
import { ICommentGlobalModel } from '@app/components/comment-store';

export class CommentsPlugin extends Plugin {
  public applyToElement(element: HTMLElement) {
    const store = createStore<ICommentGlobalModel, ICommentGlobalModel>(globalModel, {
      initialState: { post: parseInt(element.dataset.comments) } as ICommentGlobalModel,
    });

    ReactDOM.render(
      <StoreProvider store={store}>
        <Comment />
      </StoreProvider>,
      element
    );
  }
}
