import { Plugin, settings } from '@dipcode/dj-core';

export class ThemeChangerPlugin extends Plugin {
  /**
   * Only execute on bootstrap.
   *
   * @protected
   * @memberof InterestDomainsCarouselPlugin
   */
  protected bootstrapOnly = true;

  /**
   * Data attribute to hook into and listen for click to change theme
   *
   * @protected
   * @static
   * @memberof ThemeChangerPlugin
   */
  protected static BUTTON_DATA_ATTRIBUTE = 'data-theme-changer';

  /**
   * Data attribute used to select theme stylesheet
   *
   * @protected
   * @static
   * @memberof ThemeChangerPlugin
   */
  protected static STYLE_DATA_ATTRIBUTE = 'data-theme-style';

  /**
   * Key used to save theme to local storage
   *
   * @protected
   * @static
   * @memberof ThemeChangerPlugin
   */
  protected static STORAGE_KEY = 'idd-theme';

  /**
   * Class to indicate that the theme is active
   *
   * @protected
   * @static
   * @memberof ThemeChangerPlugin
   */
  protected static ACTIVE_CLASS = 'active';

  protected activeTheme: string = '';

  constructor(selector: string) {
    super(selector);

    document.addEventListener('DOMContentLoaded', () => this.setThemeFromStorage());
  }

  /**
   * Listens for theme change click and sets the clicked theme.
   *
   * @protected
   * @param {Element} element
   * @memberof ThemeChangerPlugin
   */
  protected applyToElement(element: Element): void {
    element.addEventListener('click', (event: MouseEvent) => {
      event.preventDefault();

      this.setTheme(element.getAttribute(ThemeChangerPlugin.BUTTON_DATA_ATTRIBUTE));
    });
  }
  /**
   * Gets the theme from local storage and sets it.
   *
   * @private
   * @memberof ThemeChangerPlugin
   */
  private setThemeFromStorage() {
    this.setTheme(localStorage.getItem(ThemeChangerPlugin.STORAGE_KEY));
  }

  private setTheme(theme: string) {
    if (!theme) {
      theme = 'light';
    }
    localStorage.setItem(ThemeChangerPlugin.STORAGE_KEY, theme);
    this.activeTheme = theme;
    this.hangleThemeChange(theme);
    this.toggleButtonActiveClass();
    this.toggleBodyThemeClass();
  }

  /**
   * Changes the theme.
   * If no styles element, does nothing.
   * If the style href is the same as the one that is already injected, does nothing.
   * Else, copies the style element into a new one and changes the src attibute. Then injects the new style into dom.
   *
   * @protected
   * @@return {*}  {void}
   * @memberof ThemeChangerPlugin
   */
  protected hangleThemeChange(theme: string): void {
    const styleElem: HTMLLinkElement = document.head.querySelector(`[${ThemeChangerPlugin.STYLE_DATA_ATTRIBUTE}]`);
    if (!styleElem) {
      return;
    }
    const newStyleUrl = this.getAssetUrl(theme);
    if (newStyleUrl === styleElem.getAttribute('href')) {
      return;
    }
    const newStyleElem: HTMLLinkElement = document.createElement('link');
    for (let index = 0; index < styleElem.attributes.length; index++) {
      newStyleElem.setAttribute(styleElem.attributes.item(index).name, styleElem.attributes.item(index).value);
    }
    newStyleElem.setAttribute('href', newStyleUrl);
    const styleElems: NodeListOf<HTMLLinkElement> = document.querySelectorAll(
      `[${ThemeChangerPlugin.STYLE_DATA_ATTRIBUTE}]`
    );
    newStyleElem.onload = () => this.onStyleLoadCallback(styleElems);
    document.head.appendChild(newStyleElem);
  }

  /**
   * Removes active class from all buttons and add it to the current one
   *
   * @private
   * @param {Element} element
   * @memberof ThemeChangerPlugin
   */
  private toggleButtonActiveClass() {
    document.querySelectorAll(`[${ThemeChangerPlugin.BUTTON_DATA_ATTRIBUTE}]`).forEach((elem: HTMLElement) => {
      if (elem.getAttribute(ThemeChangerPlugin.BUTTON_DATA_ATTRIBUTE) === this.activeTheme) {
        elem.classList.add(ThemeChangerPlugin.ACTIVE_CLASS);
      } else {
        elem.classList.remove(ThemeChangerPlugin.ACTIVE_CLASS);
      }
    });
  }

  private toggleBodyThemeClass() {
    const classPrefix = 'idd-theme-';
    const bodyElement = document.querySelector('body');
    const bodyClasses = bodyElement.classList;
    bodyClasses.forEach((className) => {
      if (className.startsWith(classPrefix)) {
        bodyElement.classList.remove(className);
      }
    });
    bodyElement.classList.add(`${classPrefix}${this.activeTheme}`);
  }

  /**
   * Get asset full url independent of environment
   *
   * @protected
   * @param {string} theme
   * @return {*}  {string}
   * @memberof ThemeChangerPlugin
   */
  protected getAssetUrl(theme: string): string {
    return settings.get(`${theme.toUpperCase()}_THEME_URL`);
  }

  /**
   * Actions to do after stylesheet is loaded, namely remove the previous one
   *
   * @private
   * @param {NodeListOf<HTMLLinkElement>} styleElems
   * @memberof ThemeChangerPlugin
   */
  private onStyleLoadCallback(styleElems: NodeListOf<HTMLLinkElement>) {
    styleElems.forEach((elem) => elem.remove());
  }
}
