import { debounce } from 'lodash';

import { Plugin, Utils } from '@dipcode/dj-core';
import { ChoicesService } from '@dipcode/dj-services';

type HTMLFormFieldElement = HTMLInputElement | HTMLSelectElement;

export class FormFieldDependencyPlugin extends Plugin {
  private static DEBOUNCE_TIME = 150;
  private static DISABLED_ATTR = 'disabled';

  /**
   * Element to apply plugin
   *
   * @private
   * @param {HTMLFormFieldElement} element
   * @memberof FormFieldDependencyPlugin
   */
  protected applyToElement(element: HTMLFormFieldElement): void {
    const dependencies: { field: HTMLFormFieldElement; values: any[] }[] = [];
    const form: HTMLFormElement = element.closest('form');

    const parsedDependencies = JSON.parse(element.dataset.formFieldDependencies);
    const fieldDependencies = parsedDependencies instanceof Array ? parsedDependencies : [parsedDependencies];

    fieldDependencies.forEach((dependency: { field: string; values: any[] }) => {
      const dependecyElem = form.querySelector<HTMLFormFieldElement>(
        `input[name='${dependency.field}'], select[name='${dependency.field}']`
      );
      const dependencyValues = dependency.values instanceof Array ? dependency.values : [dependency.values];
      dependencies.push({ field: dependecyElem, values: dependencyValues });
    });

    const onDependencyChangeEvent = debounce(
      () => this.onDependencyChangeEvent(dependencies, element),
      FormFieldDependencyPlugin.DEBOUNCE_TIME
    );

    dependencies.forEach((dependency: { field: HTMLFormFieldElement; values: any[] }) => {
      dependency.field.addEventListener('change', onDependencyChangeEvent);
    });

    this.onDependencyChangeEvent(dependencies, element);
  }

  /**
   * Show or hide element according to its dependencies values
   *
   * @private
   * @param {{ field: HTMLFormFieldElement; values: any[] }[]} dependencies
   * @param {HTMLFormFieldElement} element
   * @memberof FormFieldDependencyPlugin
   */
  private onDependencyChangeEvent(
    dependencies: { field: HTMLFormFieldElement; values: any[] }[],
    element: HTMLFormFieldElement
  ) {
    var show = true;

    for (const dependency of dependencies) {
      show = this.validFieldValue(dependency.field, dependency.values);
      if (!show) break;
    }

    if (show) {
      if (ChoicesService.isChoicesSelect(element)) ChoicesService.getChoiceInstance(element.name).enable();
      else element.removeAttribute(FormFieldDependencyPlugin.DISABLED_ATTR);

      element.closest('[class*="col-"]').classList.remove(Utils.HIDE_CLASS);
    } else {
      if (ChoicesService.isChoicesSelect(element)) ChoicesService.getChoiceInstance(element.name).disable();
      else element.setAttribute(FormFieldDependencyPlugin.DISABLED_ATTR, '');

      element.closest('[class*="col-"]').classList.add(Utils.HIDE_CLASS);
    }
  }

  /**
   * Checks if the value of an element belongs to a list of values
   *
   * @private
   * @param {HTMLFormFieldElement} element
   * @param {any[]} values
   * @memberof FormFieldDependencyPlugin
   */
  private validFieldValue(element: HTMLFormFieldElement, values: any[]) {
    const elementValue = element.type === 'checkbox' ? (element as HTMLInputElement).checked : element.value;
    if (values.includes('*')) return elementValue != '';
    return values.includes(elementValue);
  }
}
