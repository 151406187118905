import { Plugin, Utils } from '@dipcode/dj-core';
import { NotificationsService } from '@dipcode/dj-services';

import { AccountsService } from '@app/services/accounts';

declare const django: any;

export class UserRemoveEntityPlugin extends Plugin {
  protected applyToElement(element: HTMLElement): void {
    const btnElem: HTMLButtonElement = element.querySelector('button');

    if (!btnElem) {
      return;
    }

    btnElem.addEventListener('click', (event: MouseEvent) => {
      event.preventDefault();

      this.toggleUserRemoveEntity(element, btnElem);
    });
  }

  private toggleUserRemoveEntity(element: HTMLElement, btnElem: HTMLButtonElement) {
    btnElem.setAttribute('disabled', 'disabled');

    AccountsService.userRemoveEntity(element.dataset.userRemoveEntity)
      .then((data: { feedback: string }) => {
        Utils.toggleElement(element, false);
        NotificationsService.show({ type: 'success', text: data.feedback });
      })
      .catch(() => {
        NotificationsService.show({
          type: 'error',
          text: django.gettext('Unable to remove the user from your entity'),
        });
      })
      .finally(() => {
        btnElem.removeAttribute('disabled');
      });
  }
}
