import { Options, Splide } from '@splidejs/splide';

import { Plugin } from '@dipcode/dj-core';

import '@splidejs/splide/dist/css/splide.min.css';

export class CarouselPlugin extends Plugin<HTMLElement> {
  protected splide: Splide;

  protected applyToElement(element: HTMLElement): void {
    this.splide = new Splide(element, this.getOptions());
    this.splide.mount();
  }

  protected getOptions(): Options {
    return {};
  }
}
