import axios, { AxiosResponse } from 'axios';

import { Plugin } from '@dipcode/dj-core';
import { NotificationsService } from '@dipcode/dj-services';

import { GoogleAnalyticsService } from '@app/services';

/**
 * Allow defining behavior on buttons to toggle flags: like and report, for instance.
 *
 * @export
 * @class FlagButtonPlugin
 * @extends {Plugin}
 */
export class FlagButtonPlugin extends Plugin {
  /**
   * CSS class used to mark as flag as active.
   *
   * @private
   * @static
   * @memberof FlagButtonPlugin
   */
  private static ACTIVE_CLASS = 'active';

  /**
   * CSS class used to mark loading state.
   *
   * @private
   * @static
   * @memberof FlagButtonPlugin
   */
  private static LOADING_CLASS = 'flag-loading';

  /**
   * Instantiate conditionally carousel.
   *
   * @memberof FlagButtonPlugin
   */
  public applyToElement(element: HTMLElement) {
    element.addEventListener('click', (event: MouseEvent) => {
      event.preventDefault();

      this.toggleFlag(element);
    });
  }

  private sendPostRequest(flag: string, clientId?: string): Promise<any> {
    return axios.post(flag, clientId ? { ga_client_id: clientId } : {}, {
      withCredentials: true,
      xsrfCookieName: 'csrftoken',
      xsrfHeaderName: 'X-CSRFToken',
    });
  }

  /**
   * Toggle flag sending data to server.
   *
   * @param element HTML element where flag will be applied.
   */
  private toggleFlag(element: HTMLElement) {
    element.classList.add(FlagButtonPlugin.LOADING_CLASS);
    element.setAttribute('disabled', 'disabled');

    GoogleAnalyticsService.getClientId()
      .then((clientId) => this.sendPostRequest(element.dataset.flag, clientId))
      .catch(() => this.sendPostRequest(element.dataset.flag))
      .then((response: AxiosResponse<{ action: string; label: string; feedback?: string }>) => {
        if (response.data.action == 'activate' && !element.classList.contains(FlagButtonPlugin.ACTIVE_CLASS)) {
          element.classList.add(FlagButtonPlugin.ACTIVE_CLASS);
        } else if (response.data.action == 'deactivate' && element.classList.contains(FlagButtonPlugin.ACTIVE_CLASS)) {
          element.classList.remove(FlagButtonPlugin.ACTIVE_CLASS);
        }

        element.querySelector('[data-flag-counter]').textContent = response.data.label;

        if (response.data.feedback) {
          NotificationsService.show({ type: 'success', text: response.data.feedback });
        }
      })
      .finally(() => {
        element.classList.remove(FlagButtonPlugin.LOADING_CLASS);
        element.removeAttribute('disabled');
      });
  }
}
