import * as React from 'react';

import { useStoreState } from 'easy-peasy';
import TimeAgo from 'react-timeago'
import ptStrings from 'react-timeago/lib/language-strings/pt-short'
import buildFormatter from 'react-timeago/lib/formatters/buildFormatter'
import { NotificationsService } from '@dipcode/dj-services';

import { User } from '@app/models';

import { CommentForm } from './CommentForm';
import { ProfilePicture } from './ProfilePicture';
import { CommentList } from './CommentList';
import { ICommentGlobalModel, ICommentReportResponse, Comments } from './comment-store';

declare const django: any;

export function CommentItem({ comment }) {
  const formatter = buildFormatter(ptStrings);

  const user = useStoreState<ICommentGlobalModel, User>((state) => state.user);
  const deleteComment = Comments.useStoreActions((actions) => actions.delete);
  const reportComment = Comments.useStoreActions((actions) => actions.report);

  const [commentObj, setCommentObj] = React.useState(comment);
  const [showReplyForm, setShowReplyForm] = React.useState(false);

  const onClickReply = (event: React.MouseEvent<HTMLAnchorElement>) => {
    event.preventDefault();
    setShowReplyForm(true);
  };

  const handleDeleteComment = (event: React.FormEvent<HTMLButtonElement>) => {
    event.preventDefault();

    if (confirm(django.gettext("Are you sure you wan't to delete this comment?")) === true) {
      deleteComment( comment.id );

      NotificationsService.show({ text: django.gettext("The comment has been successfully deleted."), type: 'success' });
    }
  };

  const handleReportComment = (event: React.FormEvent<HTMLButtonElement>) => {
    event.preventDefault();

    reportComment( comment ).then((data: ICommentReportResponse) => {
      let newCommentObj = Object.assign({}, commentObj);
      newCommentObj.reported = data.action === "activate";

      setCommentObj(newCommentObj);

      NotificationsService.show({ text: data.feedback, type: 'success' });
    });
  };

  return (
    <div className="comment-block">
      <div className="comment-top">
        <div className="profile-picture">
          <ProfilePicture logo={commentObj.user.logo} />

          <span className="text-primary"><a href={commentObj.user.url}>{commentObj.user.name}</a></span>
          <span className="d-none d-lg-inline-block name-separator">|</span>
          <span className="d-none d-lg-inline-block text-primary"><a href={commentObj.user.entity.url}>{commentObj.user.entity.title}</a></span>
        </div>

        <div className="comment-top-right">
          <TimeAgo date={commentObj.created} formatter={formatter} />

          <div className="dropdown d-inline-block">
            <button
              className="dropdown-toggle btn"
              type="button"
              id={'commentActions' + commentObj.id}
              role="button"
              data-bs-toggle="dropdown"
              arias-haspopup="true"
              aria-expanded="false"
            >
              <i className="idd-icon idd-icon-more-vertical body-color" aria-hidden="true"></i>
            </button>
            <ul className="dropdown-menu dropdown-menu-end" aria-labelledby={'commentActions' + commentObj.id}>
              {user?.id == commentObj.user.id ? (
                <li>
                  <button className="dropdown-item bg-danger" type="button" onClick={handleDeleteComment}>{django.gettext('Delete')}</button>
                </li>
              ) : (
                <li>
                  <button className="dropdown-item" type="button" onClick={handleReportComment}>{commentObj.reported ? django.gettext("Reported") : django.gettext("Report")}</button>
                </li>
              )}
            </ul>
          </div>
        </div>
      </div>

      <div className={'comment-body ' + (commentObj.level === 1 ? 'main-thread' : '')}>
        <p>{commentObj.comment}</p>

        { commentObj.level < 2 && (
          <Comments.Provider>
            {showReplyForm ? (
              <CommentForm parent={comment} onCancel={() => setShowReplyForm(false)} />
            ) : (
              <a href="#" onClick={onClickReply}>
                Reply
              </a>
            )}
            <div className="comment-replies">
              <CommentList thread={commentObj.thread} level={commentObj.level + 1} />
            </div>
          </Comments.Provider>
        )}
      </div>
    </div>
  );
}
