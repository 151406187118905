import axios from 'axios';

import { settings } from '@dipcode/dj-core';

export class EventService {
  public static getEventsDates(start_limit: string, end_limit: string): Promise<any> {
    const params = { start_limit: start_limit, end_limit: end_limit };
    return axios
      .get(`${settings.get('SITE_BASE_URL')}/api/v1/events/dates/`, { params: params })
      .then((response) => response.data);
  }
}
