import { Plugin } from '@dipcode/dj-core';

export class HeaderMenuTogglerPlugin extends Plugin {
  /**
   * Only execute on bootstrap.
   *
   * @protected
   * @memberof HeaderMenuTogglerPlugin
   */
  protected bootstrapOnly = true;

  private readonly bodyClass = 'header-menu-expanded';

  protected applyToElement(element: Element): void {
    element.addEventListener('shown.bs.collapse', () => document.body.classList.add(this.bodyClass));
    element.addEventListener('hidden.bs.collapse', () => document.body.classList.remove(this.bodyClass));
  }
}
