import { Plugin } from '@dipcode/dj-core';
import { IChangeEventDetail, ChangeEventDetailType } from '@app/models';

export class ToggleButtonPlugin extends Plugin {
  static EVENT_KEY = 'toggle';

  static ACTIVE_CLASS = 'active';

  private dataset: DOMStringMap;

  protected applyToElement(element: HTMLElement): void {
    this.dataset = element.dataset;

    const toggleValue = element.dataset.toggleButton;
    const inputElement = element.querySelector<HTMLInputElement>('input');

    const deselectElement = element.querySelector<HTMLElement>('[data-toggle-button-deselect]');

    deselectElement.addEventListener('click', (event: MouseEvent) =>
      this.onDeselect(element, event, toggleValue, inputElement)
    );

    element.addEventListener('click', (event: MouseEvent) => this.onSelect(element, event, toggleValue, inputElement));
  }

  private onSelect(element: HTMLElement, event: MouseEvent, value: string, inputElement: HTMLInputElement = null) {
    event.preventDefault();

    element.classList.add(ToggleButtonPlugin.ACTIVE_CLASS);

    if (inputElement) {
      inputElement.checked = true;
      element.dispatchEvent(new Event('change', { bubbles: true }));
    }
    this.triggerEvent(element, value, 'selected');
  }

  private onDeselect(element: HTMLElement, event: MouseEvent, value: string, inputElement: HTMLInputElement = null) {
    event.stopPropagation();

    element.classList.remove(ToggleButtonPlugin.ACTIVE_CLASS);

    if (inputElement) {
      inputElement.checked = false;
      element.dispatchEvent(new Event('change', { bubbles: true }));
    } else {
      this.triggerEvent(element, value, 'deselected');
    }
  }

  private triggerEvent(element: HTMLElement, id: string, type: ChangeEventDetailType) {
    element.dispatchEvent(
      new CustomEvent<IChangeEventDetail>(ToggleButtonPlugin.EVENT_KEY, {
        detail: { id, type, dataset: this.dataset },
        bubbles: true,
      })
    );
  }
}
