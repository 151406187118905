import { ThemeChangerPlugin } from './theme-changer';

export class ThemeChangerPluginDev extends ThemeChangerPlugin {
  /**
   * Data attribute used to select theme script
   *
   * @private
   * @static
   * @memberof ThemeChangerPlugin
   */
  private static SCRIPT_DATA_ATTRIBUTE = 'data-theme-script';

  /**
   * Changes the theme.
   * If no script element, does nothing.
   * If the script url is the same as the one that is already injected, does nothing.
   * Else, copies the script element into a new one and changes the src attibute. Then injects the new script into dom.
   *
   * @protected
   * @return {*}  {void}
   * @memberof ThemeChangerPluginDev
   */
  protected hangleThemeChange(theme: string): void {
    const scriptElem: HTMLScriptElement = document.querySelector(`[${ThemeChangerPluginDev.SCRIPT_DATA_ATTRIBUTE}]`);
    if (!scriptElem) {
      return;
    }
    const newScriptUrl = this.getAssetUrl(theme);
    if (newScriptUrl === scriptElem.getAttribute('src')) {
      return;
    }
    const styleElems: NodeListOf<HTMLStyleElement> = document.querySelectorAll(
      // FIXME: Seems kinda of an hacky solution
      `[${ThemeChangerPlugin.STYLE_DATA_ATTRIBUTE}]:last-of-type`
    );
    const newScriptElem: HTMLScriptElement = document.createElement('script');
    for (let index = 0; index < scriptElem.attributes.length; index++) {
      newScriptElem.setAttribute(scriptElem.attributes.item(index).name, scriptElem.attributes.item(index).value);
    }
    newScriptElem.setAttribute('src', newScriptUrl);
    newScriptElem.onload = () => this.onScriptLoadCallback(styleElems, scriptElem);
    document.body.appendChild(newScriptElem);
  }

  /**
   * Actions to do after script is loaded, namely remove the previous styles and the previous script
   *
   * @private
   * @param {NodeListOf<HTMLStyleElement>} styleElems
   * @param {HTMLScriptElement} scriptElem
   * @memberof ThemeChangerPluginDev
   */
  private onScriptLoadCallback(styleElems: NodeListOf<HTMLStyleElement>, scriptElem: HTMLScriptElement) {
    (styleElems || []).forEach((elem: HTMLStyleElement) => elem.remove());
    scriptElem.remove();
  }
}
