import axios from 'axios';
import { debounce } from 'lodash';
import serialize from 'form-serialize';

import { Plugin, Utils } from '@dipcode/dj-core';

export class FetchResultsPlugin extends Plugin {
  /**
   * Event listener debounce time (ms)
   *
   * @private
   * @static
   * @memberof FetchResultsPlugin
   */
  private static DEBOUNCE_TIME = 300;

  /**
   * Input type to exclude from being able to influence the results update.
   *
   * @private
   * @static
   * @memberof FetchResultsPlugin
   */
  private static INPUT_TYPE_EXCLUDE_CHANGE_EVENT = ['text', 'email', 'number', 'range', 'date'];

  /**
   * Url to which the data will be submitted.
   *
   * @private
   * @type {string}
   * @memberof FetchResultsPlugin
   */
  private formAction: string;

  /**
   * HTML element thats represents the spinner.
   *
   * @private
   * @type {HTMLElement}
   * @memberof FetchResultsPlugin
   */
  private spinnerElement: HTMLElement;

  /**
   * HTML element where the results will be placed.
   *
   * @private
   * @type {HTMLElement}
   * @memberof FetchResultsPlugin
   */
  private resultsWrapperElement: HTMLElement;

  /**
   * Last search url params.
   *
   * @private
   * @type {string}
   * @memberof FetchResultsPlugin
   */
  private lastSearchParams: string = '';

  /**
   * Apply plugin to this element.
   *
   * @param {HTMLFormElement} element
   * @memberof FetchResultsPlugin
   */
  public applyToElement(element: HTMLFormElement) {
    this.formAction = element.getAttribute('action');
    this.spinnerElement = element.querySelector<HTMLElement>('[data-live-spinner]');
    this.resultsWrapperElement = element.querySelector<HTMLElement>('[data-live-results-wrapper]');

    this.resultsWrapperElement.style.transition = 'opacity 100ms linear';

    // umbind default form submit to avoid submition with full page render
    element.addEventListener('submit', (event: Event) => {
      event.preventDefault();

      this.sendRequest(element);
    });

    // Subscribe change event listner only for some kind of inputs, like select, checkbox, etc...
    // For inputs like text, email, number, etc... the event is only triggered when user blurs the input.
    element.addEventListener(
      'change',
      debounce((event: Event) => {
        const targetElement = event.target as HTMLInputElement;

        if (!FetchResultsPlugin.INPUT_TYPE_EXCLUDE_CHANGE_EVENT.includes(targetElement.type)) {
          this.sendRequest(element);
        }
      }, FetchResultsPlugin.DEBOUNCE_TIME)
    );

    // Catch changes on inputs without need of bluring the input
    element.addEventListener(
      'input',
      debounce((event: Event) => {
        const targetElement = event.target as HTMLInputElement;

        if (FetchResultsPlugin.INPUT_TYPE_EXCLUDE_CHANGE_EVENT.includes(targetElement.type)) {
          this.sendRequest(element);
        }
      }, FetchResultsPlugin.DEBOUNCE_TIME)
    );

    this.lastSearchParams = serialize(element);

    element.dispatchEvent(
      new CustomEvent<{ params: URLSearchParams }>('searchchange', {
        detail: { params: new URLSearchParams(this.lastSearchParams) },
        bubbles: true,
      })
    );
  }

  /**
   * Submit data to server.
   *
   * @private
   * @param {HTMLFormElement} formElement
   * @memberof FetchResultsPlugin
   */
  private sendRequest(formElement: HTMLFormElement) {
    const serializedForm = serialize(formElement);

    if (serializedForm === this.lastSearchParams) {
      return;
    }

    this.toggleSpinner(true);

    this.lastSearchParams = serializedForm;
    const params = new URLSearchParams(serializedForm);

    this.updateURLSearchParams(params);

    formElement.dispatchEvent(
      new CustomEvent<{ params: URLSearchParams }>('searchchange', {
        detail: { params: params },
        bubbles: true,
      })
    );

    axios
      .request({
        url: this.formAction,
        method: 'GET',
        params,
        withCredentials: true,
      })
      .then((response) => {
        this.resultsWrapperElement.innerHTML = response.data;
        this.router.applyPlugins({ rootElement: this.resultsWrapperElement });
      })
      .finally(() => this.toggleSpinner(false));
  }

  /**
   * Toggle spinner element.
   *
   * @param show
   */
  private toggleSpinner(show: boolean = true) {
    Utils.toggleElement(this.spinnerElement, show);
    this.resultsWrapperElement.style.opacity = show ? '0.2' : '1';
  }

  /**
   * Update URL on browser with search paramneters.
   *
   * @private
   * @param {URLSearchParams} params
   * @memberof FetchResultsPlugin
   */
  private updateURLSearchParams(params: URLSearchParams) {
    if (window.history && window.history.replaceState) {
      const currentUrl = new URL(window.location.href);
      currentUrl.search = '';

      params.forEach((value: string, key: string) => currentUrl.searchParams.append(key, value));

      window.history.replaceState({}, '', currentUrl.toString());
    }
  }
}
