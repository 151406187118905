import { Plugin, settings } from '@dipcode/dj-core';

export class EventFilterDatePlugin extends Plugin {
  public applyToElement(element: HTMLInputElement) {
    let titleElement = document.querySelector<HTMLElement>('[data-event-title]');
    let initialTitle = titleElement.innerHTML;

    element.addEventListener('change', () => {
      this.updateTitle(titleElement, element.value, initialTitle);
    });

    this.updateTitle(titleElement, element.value, initialTitle);
  }

  updateTitle(titleElement: HTMLElement, value: string, defaultTitle: string) {
    if (value) {
      const date = new Date(value);

      titleElement.innerHTML = date.toLocaleDateString(settings.get('LANGUAGE') || 'default', {
        year: 'numeric',
        month: 'long',
        day: '2-digit',
      });
    } else {
      titleElement.innerHTML = defaultTitle;
    }
  }
}
