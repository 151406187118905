import axios from 'axios';

import { App, Routes, settings } from '@dipcode/dj-core';

export class IDDApp extends App {
  /**
   * Bootstrap application.
   *
   * @static
   * @returns
   * @memberof App
   */
  public static bootstrap(routes: Routes) {
    return new IDDApp(routes);
  }

  /**
   * Creates an instance of App.
   *
   * @memberof App
   */
  constructor(routes: Routes) {
    super(routes);

    this.setupAxiosConfig();
  }

  setupAxiosConfig() {
    axios.defaults.headers.common['Accept-Language'] = settings.get('LANGUAGE');
    axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest';
  }
}
