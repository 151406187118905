import axios from 'axios';
import autoComplete from '@tarekraafat/autocomplete.js';

import { Plugin } from '@dipcode/dj-core';

export class AutocompletePlugin extends Plugin {
  private static AUTOCOMPLETE_THRESHOLD = 3; // minimum characters length where autoComplete engine starts
  private static AUTOCOMPLETE_DEBOUNCE = 300;
  private static AUTOCOMPLETE_MAX_RESULTS = 20;

  protected applyToElement(_element: HTMLInputElement): void {
    const autoCompleteJS = new autoComplete({
      selector: this.selector,
      wrapper: false,
      data: {
        src: async (query) => {
          const response = await axios.get(autoCompleteJS.input.dataset.autocomplete, { params: { q: query } });
          return response.data?.results;
        },
        keys: ['label'],
      },
      threshold: AutocompletePlugin.AUTOCOMPLETE_THRESHOLD,
      debounce: AutocompletePlugin.AUTOCOMPLETE_DEBOUNCE,
      resultsList: {
        tag: 'div',
        class: 'autocomplete-list',
        maxResults: AutocompletePlugin.AUTOCOMPLETE_MAX_RESULTS,
        noResults: true,
      },
      resultItem: {
        tag: 'div',
        class: 'autocomplete-item',
        highlight: true,
      },
      events: {
        input: {
          selection: (event) => {
            autoCompleteJS.input.value = event.detail.selection.value.label;
          },
        },
      },
    });
  }
}
