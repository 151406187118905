import { Routes, settings } from '@dipcode/dj-core';
import { FontLoaderScript, IdleScript } from '@dipcode/dj-scripts';
import {
  AutosizeTextAreaPlugin,
  BackToTopPlugin,
  DjangoMessagesPlugin,
  FormValidationPlugin,
  FormsetPlugin,
  InfiniteScrollPlugin,
  SubmitOnFormChangePlugin,
  RecaptchaPlugin,
} from '@dipcode/dj-plugins';

import {
  AnalyticsClientIdPlugin,
  BTIDPopulatorPlugin,
  CalendarCarouselPlugin,
  CarouselOnMatchPlugin,
  CarouselPlugin,
  ChartPlugin,
  CommentsPlugin,
  EntitiesMapPlugin,
  EventFilterDatePlugin,
  FilterElementsPlugin,
  FixedSubmitContainerPlugin,
  FlagButtonPlugin,
  FormFieldDependencyPlugin,
  HeaderMenuTogglerPlugin,
  ImagePreviewerPlugin,
  InterestDomainsCarouselPlugin,
  InterestDomainsSelector,
  LaddaPlugin,
  ManagersActionsPlugin,
  MarkForDeletePlugin,
  SimpleBarPlugin,
  ThemeChangerPlugin,
  ThemeChangerPluginDev,
  ToggleButtonPlugin,
  UnregisteredEntityTogglerPlugin,
  UserRemoveEntityPlugin,
} from '@app/plugins';
import {
  AutocompletePlugin,
  ClearableFilePluginPlugin,
  FetchResultsPlugin,
  LinkInterpreterPlugin,
  PasswordVisibilityPlugin,
  SelectBoxPlugin,
} from '@app/plugins/forms';
import { BootstrapScript } from '@app/scripts';

/**
 * @description
 *
 * Represents router configuration.
 *
 * `Routes` is an array of route configurations. Each one has the following properties:
 *
 * - `match` define regex matching rule. If not defined, will always execute the view.
 * - `plugins` is the plugins to be applied.
 *
 * Example:
 * ```typescript
 * [
 *   { plugins: [DjangoMessagesPlugin()] },
 *   { match: /homepage/, plugins: [HomepagePlugin()] },
 *   { match: /accounts_*./, plugins: [AccountsPlugin()] },
 * ]
 * ```
 */
export const routes: Routes = [
  {
    plugins: [
      new (settings.get('DEBUG') ? ThemeChangerPluginDev : ThemeChangerPlugin)('[data-theme-changer]'),
      new FontLoaderScript('Inter', [100, 300, 400, 500, 700]),
      new BootstrapScript(),
      new IdleScript(settings.get('REFRESH_AFTER_IDLE_TIME')),
      new DjangoMessagesPlugin('[data-django-messages-plugin]'),
      new LaddaPlugin('[type="submit"]:not([formtarget="_blank"]):not([data-noladda])'),
      new SimpleBarPlugin('[data-custom-scroll]'),
      new BackToTopPlugin('[data-back-to-top]'),
      new FormValidationPlugin('[data-form-validation]'),
      new SelectBoxPlugin('select,[data-text-choices]'),
      new PasswordVisibilityPlugin('input[type="password"]'),
      new HeaderMenuTogglerPlugin('[data-header-menu-toggler]'),
      new RecaptchaPlugin('.g-recaptcha', {
        publicKey: settings.get('RECAPTCHA_PUBLIC_KEY'),
        language: settings.get('LANGUAGE'),
      }),
      new InterestDomainsSelector('[data-interest-domains]'),
      new FixedSubmitContainerPlugin('[data-fixed-submit-container]'),
      new SubmitOnFormChangePlugin('[data-submit-form-on-change]'),
      new CarouselPlugin('[data-carousel]'),
      new InterestDomainsCarouselPlugin('[data-interest-domains-carousel]'),
      new FormFieldDependencyPlugin('[data-form-field-dependencies]'),
      new InfiniteScrollPlugin('[data-infinite-scroll]'),
      new ImagePreviewerPlugin('[data-image-previewer]'),
      new FormsetPlugin('[data-formset]'),
      new AutosizeTextAreaPlugin('textarea'),
      new ToggleButtonPlugin('[data-toggle-button]'),
      new FilterElementsPlugin('[data-filter]'),
      new LinkInterpreterPlugin('[data-link-interpreter]'),
      new MarkForDeletePlugin('[data-mark-for-delete]'),
      new ClearableFilePluginPlugin('[data-clearable-file]'),
      new AutocompletePlugin('[data-autocomplete]'),
      new ChartPlugin('[data-chartjs]'),
      new AnalyticsClientIdPlugin('[data-analytics-client-id]'),
    ],
  },
  {
    match: /template-homepage/,
    plugins: [new CarouselOnMatchPlugin('[data-carousel-on-match]')],
  },
  {
    match: /personal_create/,
    plugins: [new UnregisteredEntityTogglerPlugin('[data-unregistered-entity-toggler]')],
  },
  {
    match: /account_profile/,
    plugins: [new ManagersActionsPlugin('[data-managers-actions]')],
  },
  {
    match: /(community_hub$|template-communityhubindexpage$|account_public_profile$|template-entitypage$)/,
    plugins: [new FlagButtonPlugin('[data-flag]'), new CommentsPlugin('[data-comments]')],
  },
  {
    match: /entity_create/,
    plugins: [new BTIDPopulatorPlugin('[data-btid-populate]')],
  },
  {
    match: /template-eventindexpage/,
    plugins: [
      new EventFilterDatePlugin('[data-event-filter-date]'),
      new CalendarCarouselPlugin('[data-calendar-carousel]'),
    ],
  },
  {
    match: /template-entityindexpage/,
    plugins: [new EntitiesMapPlugin('[data-entities-map]')],
  },
  // Must be instantiated after the map plugin
  {
    plugins: [new FetchResultsPlugin('[data-live-result-fetch]')],
  },
  {
    match: /account_public_profile/,
    plugins: [new UserRemoveEntityPlugin('[data-user-remove-entity]')],
  },
];
