import { Options } from '@splidejs/splide';

import { CarouselPlugin } from './carousel';

export class InterestDomainsCarouselPlugin extends CarouselPlugin {
  protected getOptions(): Options {
    return Object.assign(super.getOptions(), {
      gap: '16px',
      mediaQuery: 'min',
      pagination: false,
      arrowPath:
        'M 14.109375 0.203125 C 13.355469 0.707031 12.914062 1.496094 13.046875 2.113281 C 13.078125 2.253906 13.152344 2.472656 13.210938 2.597656 C 13.292969 2.769531 14.589844 4.097656 18.433594 7.953125 C 21.246094 10.769531 23.550781 13.09375 23.550781 13.113281 C 23.550781 13.132812 18.566406 13.148438 12.476562 13.148438 L 1.402344 13.148438 L 1.078125 13.257812 C 0.703125 13.378906 0.449219 13.574219 0.269531 13.878906 C -0.09375 14.496094 -0.09375 15.503906 0.269531 16.121094 C 0.523438 16.554688 0.898438 16.765625 1.5625 16.847656 C 1.832031 16.882812 5.667969 16.898438 12.773438 16.898438 L 23.574219 16.898438 L 18.445312 22.035156 C 14.59375 25.898438 13.292969 27.230469 13.210938 27.402344 C 13.152344 27.527344 13.078125 27.746094 13.046875 27.886719 C 12.914062 28.503906 13.375 29.328125 14.121094 29.808594 L 14.414062 30 L 15.585938 30 L 15.878906 29.804688 C 16.070312 29.679688 18.425781 27.355469 22.574219 23.203125 C 26.21875 19.554688 29.085938 16.722656 29.230469 16.625 C 29.535156 16.429688 29.699219 16.222656 29.871094 15.835938 C 29.984375 15.582031 30 15.476562 30 15 C 30 14.523438 29.984375 14.417969 29.871094 14.164062 C 29.707031 13.789062 29.539062 13.574219 29.285156 13.414062 C 29.167969 13.34375 26.152344 10.363281 22.574219 6.796875 C 18.726562 2.957031 15.976562 0.246094 15.828125 0.152344 C 15.589844 0.00390625 15.5625 0 14.996094 0 L 14.410156 0 L 14.109375 0.203125',
      breakpoints: {
        992: {
          perPage: 5,
        },
        768: {
          perPage: 3,
        },
        576: {
          perPage: 1,
        },
      },
    });
  }
}
