import axios from 'axios';

import { Plugin, Utils } from '@dipcode/dj-core';
import { IChangeEventDetail } from '@app/models';

import { ToggleButtonPlugin } from './toggle-button';

interface IDepthWrappers {
  [depth: string]: HTMLElement;
}

export class InterestDomainsSelector extends Plugin {
  static HIDDEN_INPUTS_SELECTOR = '[data-interest-domains-hidden]';
  static DEPTH_WRAPPER_SELECTOR = '[data-interest-domains-depth]';
  static SPINNER_SELECTOR = '[data-live-spinner]';
  static INTEREST_DOMAIN_SELECTOR = '[data-toggle-button]';

  private element: HTMLElement;
  private hiddenInputsWrapper: HTMLElement;
  private inputName: string;
  private endpoint: string;
  private depthWrappers: IDepthWrappers = {};
  private spinnerElement: HTMLElement;

  private selectedValues: Set<string> = new Set<string>();

  protected applyToElement(element: HTMLElement): void {
    this.element = element;
    this.inputName = element.dataset.interestDomains;
    this.endpoint = element.dataset.interestDomainsUrl;
    this.hiddenInputsWrapper = element.querySelector<HTMLElement>(InterestDomainsSelector.HIDDEN_INPUTS_SELECTOR);
    this.depthWrappers = this.getDepthWrappers();
    this.spinnerElement = element.querySelector<HTMLElement>(InterestDomainsSelector.SPINNER_SELECTOR);

    this.hiddenInputsWrapper
      .querySelectorAll<HTMLInputElement>(`input[type="hidden"][name="${this.inputName}"]`)
      .forEach((elem) => this.selectedValues.add(elem.value));

    this.initialize(Math.min(...Object.keys(this.depthWrappers).map((x) => parseInt(x, 10))));
  }

  private initialize(depth: number) {
    this.depthWrappers[depth]
      .querySelectorAll<HTMLElement>(InterestDomainsSelector.INTEREST_DOMAIN_SELECTOR)
      .forEach((interestDomainBtn) => {
        interestDomainBtn.addEventListener(ToggleButtonPlugin.EVENT_KEY, (event: CustomEvent<IChangeEventDetail>) => {
          this.handleButtonEventChange(event, depth);
        });
      });
  }

  private handleButtonEventChange(event: CustomEvent<IChangeEventDetail>, depth: number) {
    this.element.dispatchEvent(new Event('change', { bubbles: true }));
    const hiddenInput = this.getHiddenInput(event.detail.id);

    this.hideAfterCurrentDepth(depth);

    if (event.detail.type === 'selected') {
      this.selectedValues.add(event.detail.id);
      if (!hiddenInput) {
        this.hiddenInputsWrapper.appendChild(
          this.createHiddenInput(event.detail.id, event.detail.dataset?.interestDomainParent)
        );
      }

      this.showNextLevel(depth, event.detail.id);
    } else {
      this.removeHiddenInput(event.detail.id, hiddenInput);
    }
  }

  private showNextLevel(depth: number, parent: string) {
    const nextDepthWrapper = this.depthWrappers[depth + 1];
    if (!nextDepthWrapper) {
      return;
    }
    Utils.toggleElement(this.spinnerElement, true);
    const allButtons = this.element.querySelectorAll<HTMLElement>(InterestDomainsSelector.INTEREST_DOMAIN_SELECTOR);
    allButtons.forEach((elem) => elem.setAttribute('disabled', ''));

    axios.get(this.endpoint, { params: this.getSearchParams(parent, this.selectedValues) }).then((response) => {
      Utils.toggleElement(this.spinnerElement, false);
      allButtons.forEach((elem) => elem.removeAttribute('disabled'));
      if (response.data) {
        nextDepthWrapper.innerHTML = response.data;
        this.router.applyPlugins({ rootElement: nextDepthWrapper });
        this.initialize(depth + 1);
      }
    });
  }

  private hideAfterCurrentDepth(depth: number) {
    Object.entries<HTMLElement>(this.depthWrappers).forEach(([key, element]) => {
      const numericKey = parseInt(key, 10);
      if (!isNaN(numericKey) && numericKey > depth && element) {
        element.innerHTML = '';
      }
    });
  }

  private getDepthWrappers(): IDepthWrappers {
    const wrappers: IDepthWrappers = {};
    this.element.querySelectorAll<HTMLElement>(InterestDomainsSelector.DEPTH_WRAPPER_SELECTOR).forEach((element) => {
      const key = parseInt(element.dataset.interestDomainsDepth, 10);
      wrappers[isNaN(key) ? 0 : key] = element;
    });
    return wrappers;
  }

  private createHiddenInput(id: string, parent: string = null): HTMLInputElement {
    const input = document.createElement('input');
    input.type = 'hidden';
    input.name = this.inputName;
    input.value = id;
    input.setAttribute('data-interest-domain-parent', parent);
    return input;
  }

  private getHiddenInput(value: string): HTMLInputElement {
    return this.hiddenInputsWrapper.querySelector<HTMLInputElement>(
      `input[type="hidden"][name="${this.inputName}"][value="${value}"]`
    );
  }

  private removeHiddenInput(value: string, element: HTMLElement = null) {
    element?.remove();
    this.selectedValues.delete(value);
    const hiddenInputs = this.element.querySelectorAll<HTMLInputElement>(
      `input[type="hidden"][name="${this.inputName}"]`
    );
    hiddenInputs.forEach((input) => {
      if (input.dataset.interestDomainParent && !this.selectedValues.has(input.dataset.interestDomainParent)) {
        this.removeHiddenInput(input.value, input);
      }
    });
  }

  private getSearchParams(parent: string, selected: Set<string>): URLSearchParams {
    const params = new URLSearchParams();
    params.set('parent', parent);
    Array.from(selected).forEach((value) => {
      if (params.has('selected')) {
        params.append('selected', value);
      } else {
        params.set('selected', value);
      }
    });
    return params;
  }
}
