import { Plugin } from '@dipcode/dj-core';

export class ManagersActionsPlugin extends Plugin {
  /**
   * Only execute on bootstrap.
   *
   * @protected
   * @memberof ManagersActionsPlugin
   */
  protected bootstrapOnly = true;

  /**
   * Attribute to select the badges
   *
   * @private
   * @memberof ManagersActionsPlugin
   */
  private readonly badgesDataAttr = 'data-entity-contact-badge';

  /**
   * Attribute to select the toggler
   *
   * @private
   * @memberof ManagersActionsPlugin
   */
  private readonly togglerDataAttr = 'data-entity-contact-toggler';

  /**
   * Attribute to select the actions element
   *
   * @private
   * @memberof ManagersActionsPlugin
   */
  private readonly actionsDataAttr = 'data-managers-actions';

  private readonly formDataAttr = 'data-manager-form';

  /**
   * Toggle elements when point of contact is changed.
   *
   * @param element Entity select
   */
  protected applyToElement(element: HTMLElement): void {
    element.querySelectorAll(`[${this.togglerDataAttr}]`).forEach((togglerInput: HTMLElement) => {
      togglerInput.addEventListener('change', () => {
        document.querySelectorAll(`[${this.togglerDataAttr}]`).forEach((checkboxInput: HTMLInputElement) => {
          const formElem = checkboxInput.closest(`[${this.formDataAttr}]`);
          const badgeElem = formElem.querySelector(`[${this.badgesDataAttr}]`);
          const actionsElem = formElem.querySelector(`[${this.actionsDataAttr}]`);

          if (checkboxInput.id !== togglerInput.id) {
            checkboxInput.checked = false;
            checkboxInput.removeAttribute('checked');

            badgeElem.classList.add('d-none');
            actionsElem.classList.remove('d-none');
          } else {
            badgeElem.classList.remove('d-none');
            actionsElem.classList.add('d-none');
          }
        });
      });
    });
  }
}
