import axios, { AxiosResponse } from 'axios';
import { debounce } from 'lodash';

import { Plugin, settings } from '@dipcode/dj-core';

export class LinkInterpreterPlugin extends Plugin {
  /**
   * Debounce time for input event
   *
   * @static
   * @memberof LinkInterpreterPlugin
   */
  static DEBOUNCE_TIME = 600;

  /**
   * Regex used to match urls in the text
   *
   * @private
   * @memberof LinkInterpreterPlugin
   */
  private regex = settings.get('URL_REGEX');

  /**
   * Cache to store the output of a given URL
   *
   * @private
   * @type {{ [url: string]: string }}
   * @memberof LinkInterpreterPlugin
   */
  private matches: { [url: string]: string } = {};

  /**
   * Endpoint to fetch partial from
   *
   * @private
   * @type {string}
   * @memberof LinkInterpreterPlugin
   */
  private partialEndpoint: string;

  /**
   * Controller to cancel the previous request
   *
   * @private
   * @memberof LinkInterpreterPlugin
   */
  private axiosAbortController = new AbortController();

  /**
   * Current element
   *
   * @private
   * @type {HTMLInputElement}
   * @memberof LinkInterpreterPlugin
   */
  private element: HTMLInputElement;

  /**
   * Elemente where to render the output
   *
   * @private
   * @type {HTMLElement}
   * @memberof LinkInterpreterPlugin
   */
  private targetElement: HTMLElement;

  protected applyToElement(element: HTMLInputElement): void {
    this.element = element;
    this.targetElement = document.querySelector<HTMLElement>('[data-post-link-card]');
    this.partialEndpoint = this.targetElement.dataset.postLinkCard;

    const onInputDebounce = debounce(() => this.handleValueChange(), LinkInterpreterPlugin.DEBOUNCE_TIME);

    element.addEventListener('input', onInputDebounce);

    const imagePreviewInputElement = document.querySelector<HTMLInputElement>('[data-image-previewer]');
    imagePreviewInputElement?.addEventListener('change', (event: Event) => {
      const target = event.target as HTMLInputElement;
      if (target.files.length > 0) {
        this.targetElement.innerHTML = '';
        element.removeEventListener('input', onInputDebounce);
      } else {
        this.handleValueChange();
        element.addEventListener('input', onInputDebounce);
      }
    });
  }

  /**
   * Handles input value change.
   * If there are urls in text, renders the info from the last valid one.
   * If there are no urls in text, sets the target element html to empty.
   *
   * @private
   * @return {*}
   * @memberof LinkInterpreterPlugin
   */
  private handleValueChange() {
    const matches = this.getMatches(this.element.value);
    if (!matches.length) {
      this.targetElement.innerHTML = '';
      return;
    }

    this.fetchLinkCard(matches)
      .then((card) => (this.targetElement.innerHTML = card || ''))
      .catch(() => (this.targetElement.innerHTML = ''));
  }

  /**
   * Given a text, it returns a list of all urls in that text
   *
   * @private
   * @param {string} text
   * @return {*}  {string[]}
   * @memberof LinkInterpreterPlugin
   */
  private getMatches(text: string): string[] {
    const regex = new RegExp(this.regex, 'gi');
    return Array.from(text.matchAll(regex)).map((item) => item[0]);
  }

  /**
   * From the last match to the first one we try to fetch de website info
   * and on the first valid response we return the data from the API.
   *
   * @private
   * @param {string[]} matches
   * @return {*}  {Promise<string>}
   * @memberof LinkInterpreterPlugin
   */
  private async fetchLinkCard(matches: string[]): Promise<string> {
    const url = matches.pop();
    if (!url) {
      return Promise.resolve('');
    }

    if (this.matches[url]) {
      return Promise.resolve(this.matches[url]);
    }

    let result: AxiosResponse<string, any>;
    this.axiosAbortController?.abort();
    this.axiosAbortController = new AbortController();
    try {
      result = await axios.get<string>(this.partialEndpoint, {
        params: { url },
        signal: this.axiosAbortController.signal,
      });
    } catch (_) {
      return this.fetchLinkCard(matches);
    }

    if (!result.data) {
      return this.fetchLinkCard(matches);
    }

    this.matches[url] = result.data || '';
    return Promise.resolve(this.matches[url]);
  }
}
