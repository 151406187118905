import axios from 'axios';
import { debounce } from 'lodash';
import Choices, { Choice } from 'choices.js';
import { DEFAULT_CLASSNAMES } from 'choices.js';

import { Plugin } from '@dipcode/dj-core';
import { ChoicesService } from '@dipcode/dj-services';

declare const django: any;

export class SelectBoxPlugin extends Plugin {
  private static DEBOUNCE_TIME = 250;

  public applyToElement(element: HTMLSelectElement) {
    const choices = ChoicesService.create(element, {
      noResultsText: django.gettext('No results'),
      noChoicesText: django.gettext('No options to select'),
      itemSelectText: django.gettext('Press to select'),
      loadingText: django.gettext('Loading...'),
      searchResultLimit: 20,
      searchEnabled: element.dataset.searchDisabled ? false : true,
      searchChoices: element.dataset.searchable ? false : true,
      searchFloor: element.dataset.searchable ? 3 : 1,
      searchFields: ['label'],
      searchPlaceholderValue: element.dataset.searchable
        ? django.gettext('Enter at least 3 characteres to search')
        : '',
      classNames: Object.assign({}, DEFAULT_CLASSNAMES, {
        containerOuter: `choices${element.classList.contains('is-invalid') ? ' is-invalid' : ''}`,
      }),
      removeItemButton: element.hasAttribute('multiple'),
      fuseOptions: {
        ignoreLocation: true,
        threshold: 0.2,
      },
    });

    if (element.dataset.setOptions) {
      this.handleDependentChoices(element, choices);
    }

    const getAndSetChoices = debounce((event: CustomEvent<{ value: string }>) => {
      axios
        .get(element.dataset.searchable, {
          params: {
            q: event.detail.value,
          },
        })
        .then(function (response) {
          let newChoices = response.data.results;
          if (element.dataset.newChoiceValue) {
            newChoices = newChoices.concat([
              { value: element.dataset.newChoiceValue, label: element.dataset.newChoiceLabel },
            ]);
          }
          choices.clearChoices();
          choices.setChoices(newChoices);
        })
        .catch((_) => {});
    }, SelectBoxPlugin.DEBOUNCE_TIME);

    if (element.dataset.searchable) {
      element.addEventListener('search', getAndSetChoices);
    }
  }

  /**
   * Used to query choices based on the dependent input value
   *
   * @private
   * @param {HTMLSelectElement} element
   * @param {Choices} choices
   * @memberof SelectBoxPlugin
   */
  private handleDependentChoices(element: HTMLSelectElement, choices: Choices) {
    const emptyChoiceList = this._getEmptyChoicesList(choices.config.choices.find((item) => !item.value));

    const dependentElement = document.querySelector<HTMLInputElement>(element.dataset.setOptions);
    if (!dependentElement.value) {
      choices.disable();
    }
    dependentElement.addEventListener('change', (event: CustomEvent<{ value: string }>) => {
      this._onDependentFieldChange(element, choices, emptyChoiceList, event.detail.value || emptyChoiceList[0].value);
      choices._triggerChange(emptyChoiceList[0].value);
    });
  }

  private _onDependentFieldChange(
    element: HTMLSelectElement,
    choices: Choices,
    emptyChoiceList: Choice[],
    value = null
  ) {
    choices.disable();
    choices.clearChoices();
    choices.setChoices(emptyChoiceList);

    if (!value) {
      return;
    }

    axios.get(element.dataset.setOptionsUrl, { params: { value } }).then((response) => {
      choices.clearChoices();
      choices.setChoices(response.data.results.concat(emptyChoiceList));
      if ((response.data.results || []).length) {
        choices.enable();
      }
    });
  }

  private _getEmptyChoicesList(emptyChoice: Choice = null): Choice[] {
    const choiceList = [];
    if (emptyChoice) {
      choiceList.push(Object.assign({}, emptyChoice, { selected: true }));
    }
    return choiceList;
  }
}
