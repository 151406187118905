import { Choice } from 'choices.js';

import { Plugin } from '@dipcode/dj-core';

export class UnregisteredEntityTogglerPlugin extends Plugin {
  /**
   * Only execute on bootstrap.
   *
   * @protected
   * @memberof UnregisteredEntityTogglerPlugin
   */
  protected bootstrapOnly = true;

  /**
   * Toggle form fields when new entity are needed.
   *
   * @param element Entity select
   */
  protected applyToElement(element: HTMLSelectElement): void {
    const inputList = element
      .closest('form')
      .querySelectorAll('input:not([name="entity"]):not([name="csrfmiddlewaretoken"])');
    const toggleableContent = document.querySelectorAll('.togglable-content');

    if (element.value === element.dataset.newChoiceValue) {
      this.showToggleabalContent(toggleableContent, inputList);
    }

    element.addEventListener('choice', (event: CustomEvent<{ choice: Choice }>) => {
      event.stopPropagation();

      if (event.detail.choice.value === element.dataset.newChoiceValue) {
        this.showToggleabalContent(toggleableContent, inputList);
      } else {
        toggleableContent.forEach((contentBlock: Element) => contentBlock.classList.add('d-none'));
        inputList.forEach((input: HTMLInputElement) => input.setAttribute('disabled', ''));
      }

      element.dispatchEvent(new Event('change'));
    });
  }

  private showToggleabalContent(toggleableContent: NodeListOf<Element>, inputList: NodeListOf<Element>) {
    toggleableContent.forEach((contentBlock: Element) => contentBlock.classList.remove('d-none'));
    inputList.forEach((input: HTMLInputElement) => input.removeAttribute('disabled'));
  }
}
