import { Plugin } from '@dipcode/dj-core';

export class PasswordVisibilityPlugin extends Plugin {
  private readonly showPasswordIcon = 'idd-icon-eye';
  private readonly hidePasswordIcon = 'idd-icon-eye-off';

  public applyToElement(element: HTMLElement) {
    const iconElement = document.createElement('i');
    iconElement.classList.add('form-password-toggler', 'idd-icon', this.showPasswordIcon);
    element.parentElement.insertBefore(iconElement, element.parentElement.children[0]);

    let showPassword = true;

    iconElement.addEventListener('click', (event: MouseEvent) => {
      event.preventDefault();

      showPassword = !showPassword;

      element.setAttribute('type', showPassword ? 'password' : 'text');
      iconElement.classList.add(showPassword ? this.showPasswordIcon : this.hidePasswordIcon);
      iconElement.classList.remove(showPassword ? this.hidePasswordIcon : this.showPasswordIcon);
    });
  }
}
