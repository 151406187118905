import { settings } from '@dipcode/dj-core';

declare const gtag: any;

export class GoogleAnalyticsService {
  public static getClientId(): Promise<any> {
    return new Promise((resolve, reject) => {
      if (!gtag || !settings.get('GA_MEASUREMENT_ID')) {
        reject();
      }
      gtag('get', settings.get('GA_MEASUREMENT_ID'), 'client_id', resolve).catch(reject);
    });
  }
}
