import axios from 'axios';
import { settings } from '@dipcode/dj-core';

export class EntityService {
  public static getBTID(pk: number): Promise<any> {
    return axios.get(`${settings.get('SITE_BASE_URL')}/api/v1/entities/btid/${pk}/`).then((response) => response.data);
  }

  public static getEntitiesList(indexPageSlug: string, params: URLSearchParams): Promise<any> {
    params.append('index_page_slug', indexPageSlug);
    return axios
      .get(`${settings.get('SITE_BASE_URL')}/api/v1/entities/`, { params: params })
      .then((response) => response.data);
  }
}
