import { Plugin, Utils } from '@dipcode/dj-core';

export class FixedSubmitContainerPlugin extends Plugin {
  /**
   * Only execute on bootstrap.
   *
   * @protected
   * @memberof FixedSubmitContainerPlugin
   */
  protected bootstrapOnly = true;

  /**
   * Toggle form fields when new entity are needed.
   *
   * @param element Entity select
   */
  protected applyToElement(element: HTMLFormElement): void {
    const buttonElement = element.querySelector<HTMLElement>('[data-form-enabler]');

    element.addEventListener(
      'change',
      () => {
        Utils.toggleElement(buttonElement, true, 'animate__fadeOutDown', 'animate__fadeInUp');
        document.body.classList.add('fixed-submit-container-active');
      },
      { once: true }
    );
  }
}
